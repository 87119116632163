import React from 'react'

function AboutUs() {
    return (
        <div>
            <div className="aboutus-top-content aboutus-top-margin">
                <div className="row">
                    <div className="col-lg-7">
                        <p className="text-subtitle">Somos una empresa de transformación digital que acerca las tendencias tecnológicas a cualquier sector. Nuestra misión es resolver las necesidades de nuestros clientes apoyándonos en las nuevas tecnologías para adaptarlas a su entorno y potenciar su transformación digital. Nuestros esfuerzos van dirigidos a crear soluciones concretas para problemas específicos, partiendo siempre de metodologías ágiles de desarrollo.</p>
                    </div>
                    <div className="col-lg-5 position-relative">
                        <img src="../img/aboutus-header.png" alt="About us" className="aboutus-top-img" />
                    </div>
                </div>
            </div>
            <div className="aboutus-middle-content">
                <div className="row">
                    <div className="col-lg-7">
                        <p className="text-normal">Trabajamos en desarrollo de productos o en proyectos para la obtención de aplicaciones a medida de las necesidades de nuestros clientes. Seguimos en todos los casos una metodología de desarrollo, optando por Métrica 3 o SCRUM en función de la tipología del proyecto, y aplicando los procedimientos y controles de nuestro sistema de gestión de la Calidad. Buscamos utilidad y eficacia a la hora de garantizar perfectos resultados. La experiencia acumulada durante todos estos años y el uso de una programación basada en una arquitectura de componentes ha propiciado la creación de gran cantidad de herramientas con las que enfrentarnos de manera ventajosa a esos proyectos de desarrollo. Así, nuestros productos están dotados de características de modularidad y disfrutan de los beneficios de la reutilización del software.</p>
                        <p className="text-normal">Nuestros valores y nuestra cultura nos diferencian “Cómo ganamos” no es solo el nombre de nuestro código de conducta. Es un reflejo de los altos estándares que nos ponemos a  nosotros mismos. Es la creencia colectiva de que el cambio tangible y positivo se puede alcanzar con la innovación y la acción. Nos fortalece el compromiso inquebrantable con la igualdad, la confianza y el apoyo mutuos. El propósito, la estrategia y la cultura de nuestra empresa nos impulsan para lograr lo imposible. Nuestro código nos guía.</p>
                    </div>
                    <div className="col-lg-5">
                        <p className="text-normal">Esto se traduce para el cliente en una mayor agilidad en la obtención de sus aplicaciones, un mantenimiento más fácil y económico, y un mayor rendimiento y calidad de dichas aplicaciones, al ser constantes la evolución y pruebas de los elementos en que se basan. Visita nuestro catálogo de proyectos de desarrollo de software, o si estás pensando en llevar a cabo un proyecto de desarrollo de software a medida en tu empresa, consúltanos sin compromiso.</p>
                    </div>
                </div>
            </div>
            <div className="aboutus-top-content">
                <div className="row">
                    <div className="col-lg-8">
                        <p className="text-subtitle">AHORA QUE CONOCES QUIÉNES SOMOS<br/>¿TE GUSTARÍA CONTRATAR NUESTROS SERVICIOS?</p>
                        <div className="row no-gutters w-100">
                            <a style={{marginRight: "20px"}} rel="noreferrer" target="_blank" href="https://meetings.hubspot.com/jlucumi" className="btn btn-outline-light col-lg-4">Agendar cita</a>
                            <a href="/contact" className="btn btn-outline-light col-lg-4">Contáctenos</a>
                        </div>                    </div>
                    <div className="col-lg-4">
                        <img src="../img/phone.png" alt="About us" className='aboutus-bottom-img' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
