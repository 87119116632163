import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'

function Navbar() {

    const [toggledNavbar, setToggledNavbar] = useState(false)

    return (
        <div className="navbar">
            <a href='/' className="navbar-brand">
                <img src="../img/logo.png" alt="Logo"/>
            </a>
            <div onClick={()=>{ setToggledNavbar(!toggledNavbar) }} className="navbar-toogle">
                Menú
            </div>
            <div className={toggledNavbar ? "navbar-links-sm" : "navbar-links"}>
                <NavLink onClick={()=>{ setToggledNavbar(false) }} activeClassName="active" exact to="/" className="navbar-link">Inicio</NavLink>
                <NavLink onClick={()=>{ setToggledNavbar(false) }} activeClassName="active" exact to="/about-us" className="navbar-link">Nosotros</NavLink>
                <NavLink onClick={()=>{ setToggledNavbar(false) }} activeClassName="active" exact to="/services" className="navbar-link">Servicios</NavLink>
                <NavLink onClick={()=>{ setToggledNavbar(false) }} activeClassName="active" exact to="/contact" className="navbar-link">Contáctanos</NavLink>
            </div>
        </div>
    )
}

export default Navbar
