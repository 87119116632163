import React from 'react'

const year = new Date().getFullYear();

function Footer() {
    return (
        <div className="footer">
            <div className="footer-column">
                <p className="footer-column-title">Servicios</p>
                <div className="footer-column-links">
                    <a className="footer-link" href="/services">Desarrollo web personalizado </a>
                    <a className="footer-link" href="/services">Desarrollo de API </a>
                    <a className="footer-link" href="/services">Desarrollo de aplicaciones personalizadas </a>
                    <a className="footer-link" href="/services">Diseño UI/UX </a>
                    <a className="footer-link" href="/services">Desarrollo de aplicaciones multiplataforma </a>
                    <a className="footer-link" href="/services">Servicios de soporte de infraestructura TI </a>
                    <a className="footer-link" href="/services">Servicios especializados de integración de software</a>
                    <a className="footer-link" href="/services">Consultoría informática</a>
                </div>
            </div>
            <div className="footer-column">
                <p className="footer-column-title">Compañía</p>
                <div className="footer-column-links">
                    <a href="/about-us" className="footer-link">Nosotros</a>
                    <a href="/contact" className="footer-link">Contáctanos</a>
                    <div className="footer-column-logo-section">
                        <div className="footer-logo">
                            <img alt="Logo" src="../img/iso.png" />
                        </div>
                        <p className="footer-copy">©{year} Jam Consulting. Todos los derechos reservados</p>
                    </div>
                </div>
            </div>
            <div className="footer-column">
                <p className="footer-column-title">Dónde estamos</p>
                <div className="footer-column-links">
                    <p className="footer-link">Avenida Ricardo J. Alfaro, Edificio The Century Tower.</p>
                    <p className="footer-link">Teléfono: +52 5589384177</p>
                    <div className="footer-social-list">
                        <a rel="noreferrer" target="_blank" href="https://www.facebook.com/Jam-Consulting-110949818151283" className="footer-social"><img src="../icons/facebook.svg" alt="Icon"/></a>
                        <a rel="noreferrer" target="_blank" href="https://instagram.com/jamcstg?utm_medium=copy_link" className="footer-social"><img src="../icons/instagram.svg" alt="Icon"/></a>
                        <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/jam-consulting-4a7b4222b/" className="footer-social"><img src="../icons/linkedin.svg" alt="Icon"/></a>
                    </div>
                    <a className="footer-link" href="/politics">Política de privacidad</a>
                    <a className="footer-link" href="/advertisement">Aviso legal</a>
                </div>
            </div>
        </div>
    )
}

export default Footer
