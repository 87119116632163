import React from 'react'

function Advertisement() {
    return (
        <div className="container p-5">
            <p className="text-title color-light mb-3">AVISO LEGAL</p>
            <p className="text-normal color-light mb-3">Datos Identificativos JAM CONSULTING, S.A. es una entidad cuyo domicilio social se encuentra en Panamá, Dorado City Center Nivel 3 Oficina 303. </p>
            <p className="text-normal color-light mb-3">Información de los Contenidos Estas páginas Web tienen por objeto presentar al público en general la información relativa a los productos y servicios de JAM CONSULTING.</p>
            <p className="text-normal color-light mb-3">Información de los Contenidos Estas páginas Web tienen por objeto presentar al público en general la información relativa a los productos y servicios de JAM CONSULTING.</p>
            <p className="text-normal color-light mb-3">El acceso a las mismas, así como el uso que pueda hacerse de la información que contienen, son de la exclusiva responsabilidad del usuario. JAM CONSULTING no se responsabilizará de ninguna consecuencia, daño o perjuicio que pudieran derivarse de este acceso o uso de información. </p>
            <p className="text-normal color-light mb-3">JAM CONSULTING adopta todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para evitar la existencia y transmisión de virus y demás componentes dañinos a los visitantes de sus páginas. No obstante, no garantiza ni se hace responsable de la ausencia de virus y/o demás componentes dañinos en sus páginas o en el servidor que las suministra, ni responderá de los daños o perjuicios que pudieran éstos, o cualquier persona que vulnere sus sistemas de seguridad, ocasionar a terceros. </p>
            <p className="text-normal color-light mb-3">Parte de los contenidos de las páginas pueden proceder de otras fuentes distintas a JAM CONSULTING, indicándose en esos casos las fuentes o presentando enlaces con las mismas. </p>
            <p className="text-normal color-light">Son seleccionados con carácter meramente orientativo para nuestros clientes y visitantes, a modo de consulta rápida de información útil. JAM CONSULTING realizará todos los esfuerzos precisos para evitar errores en la información presentada y, en su caso, repararlos o actualizarlos lo antes posible, no pudiendo garantizar su inexistencia ni que el contenido de la información se encuentre permanentemente actualizado.</p>
        </div>
    )
}

export default Advertisement


