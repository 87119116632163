import React from 'react'

function Politics() {
    return (
        <div className="container p-5">
            <p className="text-title color-light mb-3">POLÍTICA DE PRIVACIDAD</p>
            <p className="text-normal color-light mb-3">En JAM CONSULTING tenemos el firme compromiso de respetar y proteger su privacidad. Por ello, la única información que el sistema guarda automáticamente, a fin de elaborar estadísticas acerca del tráfico en nuestra página, es la dirección IP de nuestros visitantes (y en la misma forma, Google Analytics; ver Aviso Legal). </p>
            <p className="text-normal color-light">NO guardamos ningún tipo de información personal y, en concreto, las direcciones de correo electrónico que los visitantes nos indiquen con el mero fin de reenviar información leída (noticias, proyectos, boletines...) a terceros. Dichas direcciones de correo SÍ serán almacenadas en los casos en los que alguien se suscriba a los Boletines de información sobre Tecnologías de la Información de JAM CONSULTING. El listado de direcciones para este fin no se proporciona a terceros, y cualquiera podrá ser eliminado de dicho fichero realizando la solicitud en la dirección de correo contacto@jamconsulting.com</p>
        </div>
    )
}

export default Politics
