import React, {useState, useEffect} from 'react'
import { Carousel  } from '@trendyol-js/react-carousel';

function Home() {

    const [loading, setLoading] = useState(true);

    const imgs = [
        "1.png",
        "2.png",
        "3.png",
        "4.jpg",
        "5.png",
        "6.png",
        "7.png",
        "8.png",
        "9.png",
        "10.png",
        "11.png",
        "12.png",
        "13.jpg",
        "14.jpg",
    ]

    const Preloader = () => {
        return (
            <div className="preloader">
                <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
            </div>
        )
        
    }
    
    const HeaderComponent = () => {
        return (
            <div className="header">
                
                <div className="header-grid">
                    <div className="header-info-container">
                        <p className="header-title">Desarrollo de software e integraciones</p>
                        <p className="text-subtitle">En JAM Consulting contamos con 20 años de trayectoria y un equipo de desarrolladores con amplia experiencia en la entrega de soluciones de software de alta calidad</p>
                        <div className="row no-gutters w-100 mt-5">
                            <a style={{marginRight: "20px"}} rel="noreferrer" target="_blank" href="https://meetings.hubspot.com/jlucumi" className="btn btn-outline-light mb-5 col-lg-4">Agendar cita</a>
                            <a href="/contact" className="btn btn-outline-light mb-5 col-lg-4">Contáctenos</a>
                        </div>
                        <p className="text-muted">Los software que creamos son modulares y permiten su crecimiento, adaptación y cambios a medio-largo plazo. Esta vía de ejecución ofrece un claro ahorro de costes a los clientes, solución de sus necesidades en cortos períodos de tiempo y la posibilidad de adaptarnos a nuevas necesidades en tiempo real</p>
                    </div>
                    <div className="header-imgs-container">
                        <img src="../img/header-img.png" alt="Header"/>
                        {/* <img className="deco-1" src="../img/form.svg" alt="Header"/> */}
                        {/* <img className="deco-2" src="../img/form.svg" alt="Header"/> */}
                    </div>
                </div>

            </div>
        )
        
    }

    // const ServiceCardComponent = ({props}) => {
    //     return (
    //         <div className={"card-service " + props.classCustom }>
    //             <img className="card-service-img" alt="Icon" src={props.img} />
    //             <p className="card-service-p" >{props.title}</p>
    //         </div>
    //     )
    // }

    const ServicesComponent = () => {
        return (
            <div className="services">
                
                <div style={{padding: "4% 4.5%"}}>
                    <p className="text-title-secondary">Cómo lo hacemos</p>
                    <div className="services-grid">
                        <div className="services-info-container">
                            <p className="text-subtitle">Sabemos que el futuro viene de la mano de la tecnología por ello en Jam Consulting apostamos por las tecnologías más punteras del mercado que aportan nuevas oportunidades y funcionalidades a los proyectos</p>
                        </div>
                        <div className="services-info-container">
                            <p className="text-muted">Nuestro compromiso en las asociaciones a largo plazo con nuestros clientes está respaldado por el servicio del ciclo de vida del desarrollo de su proyecto abarcando todos los aspectos, desde el concepto hasta la implementación.</p>
                            <p className="text-muted">Nos especializamos en software personalizado, desarrollos web, aplicaciones móviles, desarrollo de productos, Integraciones y análisis de datos, sensibles para cualquier industria o necesidad empresarial.</p>
                            <a href="/services" className="btn btn-outline-light">Servicios</a>
                        </div>
                    </div>
                </div>

                <div className="services-carousel bg-white">
                    <Carousel show={5} slide={5} transition={0.5} swiping={true} infinite={true} useArrowKeys={false}>
                        { imgs.map((imgSrc, i) => <img alt="Logo brand" className="services-carousel-img" src={"../img/logos/" + imgSrc} key={i} />) }
                    </Carousel>
                </div>

            </div>
        )
    }
    
    // const ServicesComponent = () => {
    //     return (
    //         <div className="services">
                
    //             <div className="services-grid">
    //                 <div className="services-info-container">
    //                     <p className="text-title-secondary">Servicios</p>
    //                     <p className="text-subtitle">Servicios de desarrollo de Software personalizado por expertos en industrias específicas</p>
    //                     <p className="text-muted">Realizamos servicios de desarrollo de software personalizados de clase mundial para empresas emergentes, pequeñas y medianas (PYMES) y empresas de tamaño empresarial.</p>
    //                     <a href="/services" className="btn btn-outline-light mb-5">Todos los servicios</a>
    //                 </div>
                    
    //                 <div className="services-info-cards">
    //                     <div className="services-info-card-column">
    //                         <ServiceCardComponent props={{title: "Desarrollo", img: "../icons/dev.png", classCustom: "mt-5"}} />
    //                         <ServiceCardComponent props={{title: "Infraestructura TI", img: "../icons/ti.png", classCustom: "mt-5"}} />
    //                     </div>
    //                     <div className="services-info-card-column">
    //                         <ServiceCardComponent props={{title: "Diseño UI/UX", img: "../icons/uiux.png", classCustom: "mb-5"}} />
    //                         <ServiceCardComponent props={{title: "Consultoría informática", img: "../icons/info.png", classCustom: "mb-5"}} />
    //                     </div>
    //                 </div>
                    
    //             </div>

    //         </div>
    //     )
    // }

    const ServiceCardWhiteComponent = ({props}) => {
        return (
            <div className="service-card-white-ts" style={{height: "40vh", margin: "0"}}>
                <img className="service-card-white-icon" alt="Icon" src={props.img} />
                <p className="service-card-white-title">{props.title}</p>
                <p className="service-card-white-text">{props.text}</p>
            </div>
        )
    }
    
    const InfoComponent = () => {
        return (
            <div className="info-home my-5">

                <p className="text-title">Expertos en desarrollo de software personalizado</p>
                <p className="text-subtitle">Nuestra gente tiene un promedio de más de 20 años de experiencia en el desarrollo de software personalizado en entornos altamente complejos. No solo entregamos código de alta calidad, nuestro equipo posee excelentes habilidades de comunicación  y es altamente productivo.</p>

                <div className="row no-gutters bg-white mt-5" style={{justifyContent: "space-between"}}>
                    <ServiceCardWhiteComponent props={{title: "Ahorros significativos", img: "../icons/dev.png", text: "Gestiona, mantiene y motiva directamente un recurso para el trabajo que se está realizando y realiza un seguimiento del tiempo en incrementos de 15 minutos con informes de estado semanales." }} />
                    <ServiceCardWhiteComponent props={{title: "Rápido, A la carta, Sin compromiso", img: "../icons/dev.png", text: "Su proyecto cuenta con personal y se inicia en menos de 2 semanas y usted tiene acceso directo a recursos de primer nivel examinados bajo demanda durante todo el año." }} />
                    <ServiceCardWhiteComponent props={{title: "Aumento o mantenimiento de personal basado en proyectos", img: "../icons/dev.png", text: "Ofrecemos acuerdos mensuales flexibles para aumentar su equipo o externalizar completamente su proyecto. También ofrecemos planes de mantenimiento personalizados para satisfacer las necesidades de su aplicación." }} />
                    <ServiceCardWhiteComponent props={{title: "Más rápido con precisión", img: "../icons/dev.png", text: "Ha desarrollado componentes que nos ayudan a reducir tiempos y  costos de desarrollo mientras aceleran la producción." }} />
                    <ServiceCardWhiteComponent props={{title: "Scrum ágil completo", img: "../icons/dev.png", text: "Podemos desplegar un equipo que incluya diseñadores de UI/UX, analistas de negocios, evaluadores de control de calidad (manuales y automatizados), DevOps, administradores de base de datos" }} />
                </div>

            </div>
        )
    }

    // const InfoComponent = () => {
    //     return (
    //         <div className="info-home">
                
    //             <div className="info-home-grid">
                    
    //                 <div className="info-home-img-container">    
    //                     <div className="info-home-img-absolute">
    //                         <img alt="Info" src="../img/home-bottom.png" />
    //                     </div>
    //                 </div>

    //                 <div className="info-home-info-container">
    //                     <p className="text-subtitle-primary">Una buena elección y asesoramiento sobre los sistemas y herramientas informáticas que han de dar soporte a una organización es esencial para que ésta se mantenga en constante rendimiento.</p>
    //                     <a href="/contact" className="btn btn-outline-primary">Solicitar demo</a>
    //                 </div>
                    
    //             </div>

    //         </div>
    //     )
    // }

    function changeLoader(){
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    }

    useEffect(() => {
        changeLoader()
    })

    return (
        <>
            {loading && <Preloader/>}
            <HeaderComponent/>
            <ServicesComponent/>
            <InfoComponent/>
        </>
    )
}

export default Home
