import React from 'react'

function Services() {

    const ServiceCardWhiteComponent = ({props}) => {
        return (
            <div className="service-card-white">
                <img className="service-card-white-icon" alt="Icon" src={props.img} />
                <p className="service-card-white-title">{props.title}</p>
                <p className="service-card-white-text">{props.text}</p>
            </div>
        )
    }

    let dictionary = {
        p1: "Ofrecemos servicios de desarrollo de aplicaciones web basados en sistemas multiplataforma, personalizamos e integramos aplicaciones existentes para planificación de recursos empresariales (ERP) entre otras funcionalidades, utilizando un entorno de programación controlado donde se puede desarrollar, instalar y ejecutarlo las mismas, permitiendo la continuidad del servicio y la sincronización de datos entre las aplicaciones.",
        p2: "Acompañamiento en el proceso de implementación y despliegue con las siguientes tareas: Configuración del hardware, instalación de aplicaciones, actualización y migración de datos, configuración y personalización de perfiles prototipos y pruebas piloto finalizando con la presentación de producción para su posterior supervisión de las aplicaciones desarrolladas.",
        p3: "Brindamos el servicio de desarrollo e integración de Su propia API con lo cual podrá conseguir que otras aplicaciones de terceros puedan comunicarse con su aplicación de una manera fácil, rápida y eficaz. De esta forma ofrecerá un acceso controlado y seguro a aplicaciones externas para el uso de sus servicios, lo que le permitirá obtener beneficios por el uso de su API.",
        p4: "Enfocamos los procesos técnicos para combinar la información de diferentes fuentes y convertirla en datos fiables y valiosos, estas soluciones de integración ayudaran a monitorizar, transformar, limpiar y entregar los datos para que su empresa pueda estar segura de que la fuente de información es confiable, consistente y está gestionada en tiempo real.",
        p5: "Realizamos seguimiento de nuestras aplicaciones desarrolladas a través de tareas de diseño, desarrollo y optimización (en código fuente, sistemas, API’S, bases de datos) con los que podamos perfeccionar y evolucionar el software en nuestras plataformas.",
        p6: "Una experiencia en cuanto a diseño de interfaz directamente dedicada a la mejora de relación de productos y servicios gracias a nuestro grupo selecto de profesionales capacitados para brindar la usabilidad, ergonomía, accesibilidad, imagen y otros aspectos que puedan afectar la interacción entre nuestros usuarios y el producto final.",
        p7: "Siempre en contacto con nuestras aplicaciones y servicios web ponemos a su disposición nuestra red para dispositivos móviles (Smartphone, tablets, smartwatches) las cuales nos facilitan el acceso a los datos de forma segura sin comprometer los mismo, debido a su sistema se seguridad encriptado bajo los más altos y estrictos niveles de calidad y servicio.",
        p8: "Siempre en contacto con nuestras aplicaciones y servicios web ponemos a su disposición nuestra red para dispositivos móviles (Smartphone, tablets, smartwatches) las cuales nos facilitan el acceso a los datos de forma segura sin comprometer los mismo, debido a su sistema se seguridad encriptado bajo los más altos y estrictos niveles de calidad y servicio."
    }

    return (
        <div>

            
            <div className="row services-top-container">
                <div className="col-lg-6">
                    <p className="text-blue-title color-light mb-5">Servicios</p>
                    <p className="text-normal color-light">Servicios de desarrollo de software personalizado por desarrolladores expertos con experiencia en industrias específicas </p>
                    <p className="text-normal color-light">Realizamos servicios de desarrollo de software personalizados de clase mundial para empresas emergentes, pequeñas y medianas (PYMES) y empresas de tamaño empresarial.</p>
                </div>
                <div className="col-lg-6">
                    <img src="../img/service-top-img.png" alt="Servicio" />
                </div>
            </div>
            <div className="services-cards-container">
                <div className="row no-gutters">
                    <ServiceCardWhiteComponent props={{title: "Desarrollo web personalizado", img: "../icons/dev.png", text: dictionary.p1 }} />
                    <ServiceCardWhiteComponent props={{title: "Implementación y despliegue", img: "../icons/dev.png", text: dictionary.p2 }} />
                    <ServiceCardWhiteComponent props={{title: "Desarrollo e integración de API'S", img: "../icons/dev.png", text: dictionary.p3 }} />
                    <ServiceCardWhiteComponent props={{title: "Integración de sistemas", img: "../icons/dev.png", text: dictionary.p4 }} />
                    <ServiceCardWhiteComponent props={{title: "Mantenimiento de aplicaciones", img: "../icons/dev.png", text: dictionary.p5 }} />
                    <ServiceCardWhiteComponent props={{title: "Diseño de UI/UX", img: "../icons/dev.png", text: dictionary.p6 }} />
                    <ServiceCardWhiteComponent props={{title: "Aplicaciones multiplataforma", img: "../icons/dev.png", text: dictionary.p7 }} />
                    <ServiceCardWhiteComponent props={{title: "Aplicaciones moviles", img: "../icons/dev.png", text: dictionary.p8 }} />
                </div>
            </div>

        </div>
    )
}

export default Services