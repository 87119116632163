import React, {useState, useEffect} from 'react'
// import * as emailjs from "emailjs-com";

function Contact() {

    // const [emailData, setEmailData] = useState({
    //     username: "",
    //     email: "",
    //     phone: "",
    //     message: "",
    // })

    // const [listErrors, setListErrors] = useState([])

    // const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const SuccessToast = () => {
        return (
            <div className="toast">
                <div className="toast-header">
                    <img src="../img/iso.png" className="rounded me-2" alt="isotipo"/>
                    <p className="me-auto mb-0 color-success">Exito</p>
                    <button onClick={()=>{ setSuccess(false) }} type="button" className="btn-close"></button>
                </div>
                <div className="toast-body color-success">
                    Tu email se ha enviado con exito
                </div>
            </div>
        )
    }
    
    const ErrorToast = () => {
        return (            
            <div className="toast">
                <div className="toast-header">
                    <img src="../img/iso.png" className="rounded me-2" alt="isotipo"/>
                    <p className="me-auto mb-0 color-danger">Error</p>
                    <button onClick={()=>{ setError(false) }} type="button" className="btn-close"></button>
                </div>
                <div className="toast-body color-danger">
                    Ha ocurrido un error
                </div>
            </div>
        )
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://share.hsforms.com/1fJ2rVXPPRHetbjBYcxCl8wcndil';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "21245997",
                    formId: "7c9dab55-73cf-4477-ad6e-30587310a5f3",
                    target: '#hubspotForm'
                })
            }
        });
    });

    // function checkInputs(object){
    //     let val = 0
    //     let array = []
    //     Object.keys(object).forEach(function(key){
    //         if(object[key] === ''){
    //             val++
    //             array.push(key)
    //         }
    //     })
    //     if(val > 0){
    //         setListErrors(array)
    //     }else{
    //         setListErrors([])
    //         handleClick()
    //     }
    // }

    return (
        <>
            {error && <ErrorToast/>}
            {success && <SuccessToast/>}

            <div className="container">
                <div className="contact-container">
                    
                    <div className="contact-info-container">
                        <p className="text-title-secondary">Contáctanos</p>
                        <p className="footer-column-title">México</p>
                        <p className="text-normal">Dr. Jose Maria Vertiz 1400 int, 202, Portales Norte C.P. 03303, Ciudad de México, CDMX</p>
                        <p className="text-normal">Teléfono: +52 5589384177</p>
                        <p className="footer-column-title">Panamá</p>
                        <p className="text-normal">Avenida Ricardo J. Alfaro, Edificio The Century Tower.</p>
                        <p className="text-normal">Teléfono: +507 6600-1192</p>
                    </div>
                    
                    <div className="contact-form-container">
                        <div id="hubspotForm"></div>
                    </div>

                </div>
            </div>
        </>
    )

    // function handleClick() {
    //     setSending(true)
    //     var data = {
    //       to_email: emailData.email,
    //       from_name: emailData.username,
    //       message: emailData.message,
    //       phone: emailData.phone,
    //     };
    
    //     emailjs.send("service_cot22ok", "template_crs5pxo", data, "user_9M19Xi2odHAN2WJilmme7").then(
    //         function (res) {
    //             console.log(res.status, res.text);
    //             if(res.status === 200){
    //                 setSending(false)
    //                 setSuccess(true)
    //                 setTimeout(() => {
    //                     setSuccess(false)
    //                 }, 3000);
    //             }else{
    //                 setSending(false)
    //                 setError(true)
    //                 setTimeout(() => {
    //                     setError(false)
    //                 }, 3000);
    //             }
    //         },
    //         function (err) {
    //             console.log(err);
    //             setSending(false)
    //             setError(true)
    //             setTimeout(() => {
    //                 setError(false)
    //             }, 3000);
    //         }
            
    //     );

    // }

}

export default Contact
