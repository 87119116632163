import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

// Components
import Footer from './components/Footer';
import Navbar from './components/Navbar';

// Pages
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Politics from './pages/Politics';
import Advertisement from './pages/Advertisement';

function App() {
  return (
    <Router>
      <Navbar/>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/about-us" exact component={AboutUs}/>
          <Route path="/services" exact component={Services}/>
          <Route path="/contact" exact component={Contact}/>
          <Route path="/politics" exact component={Politics}/>
          <Route path="/advertisement" exact component={Advertisement}/>
        </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
